import { graphql } from "gatsby"
import React from "react"

import * as s from "../assets/css/blog.module.css"
import Seo from "../components/seo"

import BlogCategories from './../components/REDESIGN/BlogCategories/BlogCategories'
import BlogMainPost from './../components/REDESIGN/BlogMainPost/BlogMainPost'
import BlogPost from './../components/REDESIGN/BlogPost/BlogPost'
import Footer from './../components/REDESIGN/footer/footer'
import Header from './../components/REDESIGN/header/header'
import NewsletterSubscribeFooterBannerNew from './../components/REDESIGN/NewsletterSubscribeFooterBannerNew/NewsletterSubscribeFooterBannerNew'
import Pagination from './../components/REDESIGN/Pagination/Pagination'
import schemaBuilder from './../utils/schemaBuilder'

const CategoryArchive = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPage, pages },
  location
}) => {

  const posts = [...data?.allWpPost?.nodes];

  const category = data?.category;

  if (currentPage > 1) {
    if (!!category.seo.metaDesc) {
      if (!category.seo.metaDesc.includes(` - Page ${currentPage}`)) {
        category.seo.metaDesc = category.seo.metaDesc + ` - Page ${currentPage}`;
      }
    }
    if (!!category.seo.opengraphDescription) {
      if (!category.seo.opengraphDescription.includes(` - Page ${currentPage}`)) {
        category.seo.opengraphDescription = category.seo.opengraphDescription + ` - Page ${currentPage}`;
      }
    }
    if (!!category.seo.twitterDescription) {
      if (!category.seo.twitterDescription.includes(` - Page ${currentPage}`)) {
        category.seo.twitterDescription = category.seo.twitterDescription + ` - Page ${currentPage}`;
      }
    }
  }

  const site = data?.site;
  const schema = schemaBuilder({ posts: posts, category: category, currentPage: currentPage }, 'category', site);

  let title = category?.name;
  if (currentPage > 1) {
    title += ` - Page ${currentPage}`;
    category.seo.title = category.seo.title.replace(' - ', ` - Page ${currentPage} - `);
  }

  return (
    <>
      <Header />
      <Seo title={title} location={location} pageSeo={category} schema={schema} />
      <div className="pb-5">
        {!!category?.categoryFeaturedPost?.mainFeaturedPost
          ? <div className="container py-5">
            <div className="row" >
              <div className="col-12">
                <BlogMainPost post={category?.categoryFeaturedPost?.mainFeaturedPost} />
              </div>
            </div>
          </div>
          : <div className="pt-5"></div>
        }
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-xl-column justify-content-between align-items-start mb-4 mb-xl-0">
              <h1 className={s.heading}>{category?.name} Articles</h1>
              <BlogCategories />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row" style={{ rowGap: '32px' }}>
                {posts.length > 0 && posts.map((post, index) => {
                  return (
                    <div className="col-12 col-md-6 col-xl-4" key={post.uri}>
                      <BlogPost post={post} />
                    </div>
                  )
                })}
                {!posts.length &&
                  <div className="col-12">
                    No blog posts found. Add posts to your WordPress site and they'll
                    appear here!
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Pagination pages={pages} currentPage={currentPage} previousPagePath={previousPagePath} nextPagePath={nextPagePath} />
        </div>

      </div>
      <NewsletterSubscribeFooterBannerNew pageTitle={`Blog page`} />
      <Footer />
    </>
  )
}

export default CategoryArchive

export const pageQuery = graphql`
  query CategoryById(
    $offset: Int!,
    $postsPerPage: Int!,
    $categoryId: String!
    ) {
    category: wpCategory(id: {eq: $categoryId}) {
      name
      uri
      categoryFeaturedPost {
        mainFeaturedPost {
          ... on WpPost {
            id
            title
            uri
            date(formatString: "MMM DD, YYYY")
            author {
              node {
                name
                uri
              }
            }
            categories {
              nodes {
                uri
                name
              }
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
            excerpt
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {id: {eq: $categoryId}}}}}
      sort: {fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        title
        uri
        date(formatString: "MMM DD, YYYY")
        author {
          node {
            name
            uri
          }
        }
        categories {
          nodes {
            uri
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
  }
`
